import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import styles from './SubscriptionDetails.module.css';
import helpers from '../../helpers.js';
import {useState, useContext, useEffect} from 'react';
import { UserContext } from '../../UserContext.js';

import DetailsTable from "../../components/subscription/DetailsTable.js";


import TextInput from "../../components/TextInput.js";
import Button from "../../components/Button.js";
import Module from "../../components/Module.js";
import Gauge from "../../components/subscription/Gauge.js";
import Overlay from "../../components/Overlay.js";
import Selector from "../../components/Selector.js";
import ProductCounter from "../../components/subscription/ProductCounter.js";
import countries from "../../assets/Countries.js";


function SubscriptionDetails(props) {
  const [progressCount, setProgressCount] = useState(0);
  const [charpoints, setCharpoints] = useState(0);
  const [subscription, setSubscription] = useState({ //DEFAULT STATE
    status: "-",
    payment_plan: "",
    supervisorName: "",
    products: [{
      _product: "63f8ddff24c72d26e1aab989",
      amount_delivered: 0,
      amount_to_deliver: 0
    },
    {
      _product: "63f8def1ed95ea8a663ec536",
      amount_delivered: 0,
      amount_to_deliver: 0
    },
  ],
    amount_due: 0,
    amount_paid: 0
  });

  useEffect(() => {
    console.log("Fetching Subscription")
    const fetchSubscription = async () => {
      console.log(props)
      let user = {};
      user.backEndURL = process.env.NODE_ENV === 'development' ? 'http://localhost:4000' : process.env.REACT_APP_API_BASE_URL;
      user.token = localStorage.getItem("token");

      if (user.token === null){
        console.log("NO TOKEN")
        user.authed = false;
      } else {
        const request = {
          method: "post",
          body: JSON.stringify({
            userId: props.user._id,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + user.token,
          }
        };
        let response = await fetch(user.backEndURL + "/fetch-subscription", request);
        let jsonResponse = await response.json();
        if (jsonResponse.success) {
          console.log(jsonResponse.data);
          setCharpoints(jsonResponse.data.charpoints);
          setSubscription(jsonResponse.data.subscription);
          setSubscription(prevState => ({...prevState, supervisorName: jsonResponse.data.supervisorName}))
          console.log("SILENTLY COMPLETES ITS MISSION, LIKE A NINJA");
        } else {
          console.log("Fetch Subscription Failed...")
        }
      }
    }
    fetchSubscription();
  },[props.user._id])
  

  return (
    <div className={helpers.clx("contentGrid", styles.contentGridNoBg)}>
      <Module icon={"user"} name={"Subscriptions Details"} spanColumn={24} height={"100%"}>
        <div className={styles.subscriptionDetailsContainer}>
          <div className={styles.leftColumn}>
            <DetailsTable user={props.user} subscription={props.subscription}/>
          </div>
          <div className={styles.rightColumn}>
            <Gauge progress={subscription.products.length === 0 ? 0 : subscription.products[0].amount_delivered} progressMax={subscription.products.length === 0 ? 0 : subscription.products[0].amount_to_deliver}/>
            <div>
              Pinchar Delivered
            </div>
            <div className={styles.gaugeSeparatorLine}/>
            <div style={{"opacity": 1, "flex-direction": "column"}}>
                  <div className={styles.charpointsContainer}>
                    {charpoints*10}<span className={styles.points}>pts</span>
                  </div>
                  <div className={styles.gaugeLabel}>
                    Charpoints
                  </div>
                </div>
          </div>
        </div>
      </Module>
    </div>
  );
}

export default SubscriptionDetails;
