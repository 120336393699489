import styles from './Nav.module.css'; //styles
import helpers from '../helpers.js';
import { useState, useContext } from 'react';
import { UserContext } from "../UserContext.js";


import logo from '../assets/images/bbbLogoWhiteTransparentNoText.png';
/*
    Navbar
        props:
            checked: boolean, sets checkbox status (checked, unchecked)
            onPress: function, action carried out when checked/unchecked
            label: str, label
            inverted: bool, if true sets label before checkbox 
*/
const Nav = (props) => {
    // const {user, setUser} = useContext(UserContext);
    const [currentScreen, setCurrentScreen] = useState(props.currentScreen);


    const changeScreens = (screen) => {
        setCurrentScreen(screen)
        props.goToScreen("/" + screen);
        console.log("HERE")
        //props.navigate
    }



    const logout = () => {
        localStorage.removeItem("token");
        let user = {
            authed: false,
            token: null,
            backEndURL: process.env.NODE_ENV === 'development' ? 'http://localhost:4000' : process.env.REACT_APP_API_BASE_URL
        }
        // setUser(user);
        props.goToScreen("/login");
    };
    // useEffect(() => { OTHER COLORS?
        
    // }, [props.color])

    

    // <li className={helpers.clx(styles.menuItem, currentScreen === "settings" ? styles.menuItemSelected : "")} onClick={() => changeScreens("settings")}>
    //                 <div className={styles.navIcon}>
    //                     wrench
    //                 </div>
    //                 Settings
    //                 <div className={helpers.clx(styles.selectedTrapezium, currentScreen === "settings" ? styles.selectedTrapeziumSelected : "")}>
    //                     <div className={helpers.clx(styles.triangleHidden, currentScreen === "settings" ? styles.triangle : "")}/>
    //                 </div>
    //             </li>

    return(
        <div className={styles.mainContainer}>
            <img src={logo} className={styles.bbbLogo}/>
            <ul className={styles.menuItems}>
                {/* <li className={helpers.clx(styles.menuItem, currentScreen === "home" ? styles.menuItemSelected : "")} onClick={() => changeScreens("home")}>
                    <div className={styles.navIcon}>
                        home
                    </div>
                    Home
                    <div className={helpers.clx(styles.selectedTrapezium, currentScreen === "home" ? styles.selectedTrapeziumSelected : "")}>
                        <div className={helpers.clx(styles.triangleHidden, currentScreen === "home" ? styles.triangle : "")}/>
                    </div>
                </li>
                <li className={helpers.clx(styles.menuItem, currentScreen === "analytics" ? styles.menuItemSelected : "")} onClick={() => changeScreens("analytics")}>
                    <div className={styles.navIcon}>
                        chart-line
                    </div>
                    Analytics
                    <div className={helpers.clx(styles.selectedTrapezium, currentScreen === "analytics" ? styles.selectedTrapeziumSelected : "")}>
                        <div className={helpers.clx(styles.triangleHidden, currentScreen === "analytics" ? styles.triangle : "")}/>
                    </div>
                </li> */}
                {props.isAdmin ? 
                    <>
                        <li className={helpers.clx(styles.menuItem, currentScreen === "admin/timeline" ? styles.menuItemSelected : "")} onClick={() => changeScreens("admin/timeline")}>
                            <div className={styles.navIcon}>
                                calendar-alt
                            </div>
                            Timeline
                            <div className={helpers.clx(styles.selectedTrapezium, currentScreen === "admin/timeline" ? styles.selectedTrapeziumSelected : "")}>
                                <div className={helpers.clx(styles.triangleHidden, currentScreen === "admin/timeline" ? styles.triangle : "")}/>
                            </div>
                        </li>
                        <li className={helpers.clx(styles.menuItem, currentScreen === "admin/farmers" ? styles.menuItemSelected : "")} onClick={() => changeScreens("admin/farmers")}>
                            <div className={styles.navIcon}>
                                tractor
                            </div>
                            Farmers
                            <div className={helpers.clx(styles.selectedTrapezium, currentScreen === "admin/farmers" ? styles.selectedTrapeziumSelected : "")}>
                                <div className={helpers.clx(styles.triangleHidden, currentScreen === "admin/farmers" ? styles.triangle : "")}/>
                            </div>
                        </li>
                        
                        {/* <li className={helpers.clx(styles.disabledAdmin, styles.menuItem, currentScreen === "orders" ? styles.menuItemSelected : "")} onClick={() => changeScreens("orders")}>
                            <div className={styles.navIcon}>
                                map
                            </div>
                            Map
                            <div className={helpers.clx(styles.selectedTrapezium, currentScreen === "orders" ? styles.selectedTrapeziumSelected : "")}>
                                <div className={helpers.clx(styles.triangleHidden, currentScreen === "orders" ? styles.triangle : "")}/>
                            </div>
                        </li> */}
                        <li className={helpers.clx( styles.menuItem, currentScreen === "admin/inventory" ? styles.menuItemSelected : "")} onClick={() => changeScreens("admin/inventory")}>
                            <div className={styles.navIcon}>
                                warehouse-alt
                            </div>
                            Inventory
                            <div className={helpers.clx(styles.selectedTrapezium, currentScreen === "admin/inventory" ? styles.selectedTrapeziumSelected : "")}>
                                <div className={helpers.clx(styles.triangleHidden, currentScreen === "admin/inventory" ? styles.triangle : "")}/>
                            </div>
                        </li>   
                        {/* <li className={helpers.clx(styles.disabledAdmin, styles.menuItem, currentScreen === "settings" ? styles.menuItemSelected : "")} onClick={() => changeScreens("orders")}>
                            <div className={styles.navIcon}>
                            truck-loading
                            </div>
                            Orders
                            <div className={helpers.clx(styles.selectedTrapezium, currentScreen === "settings" ? styles.selectedTrapeziumSelected : "")}>
                                <div className={helpers.clx(styles.triangleHidden, currentScreen === "settings" ? styles.triangle : "")}/>
                            </div>
                        </li>
                        <li className={helpers.clx(styles.disabledAdmin, styles.menuItem, currentScreen === "settings" ? styles.menuItemSelected : "")} onClick={() => changeScreens("settings")}>
                            <div className={styles.navIcon}>
                            truck
                            </div>
                            Deliveries
                            <div className={helpers.clx(styles.selectedTrapezium, currentScreen === "settings" ? styles.selectedTrapeziumSelected : "")}>
                                <div className={helpers.clx(styles.triangleHidden, currentScreen === "settings" ? styles.triangle : "")}/>
                            </div>
                        </li> */}
                        <li className={helpers.clx(styles.menuItem, currentScreen === "admin/charcodes" ? styles.menuItemSelected : "")} onClick={() => changeScreens("admin/charcodes")}>
                            <div className={styles.navIcon}>
                                qrcode
                            </div>
                            Charcodes
                            <div className={helpers.clx(styles.selectedTrapezium, currentScreen === "admin/charcodes" ? styles.selectedTrapeziumSelected : "")}>
                                <div className={helpers.clx(styles.triangleHidden, currentScreen === "admin/charcodes" ? styles.triangle : "")}/>
                            </div>
                        </li>     
                        {/* <li className={helpers.clx(styles.disabledAdmin, styles.menuItem, currentScreen === "admin/team" ? styles.menuItemSelected : "")} onClick={() => changeScreens("admin/team")}>
                            <div className={styles.navIcon}>
                                 users
                            </div>
                            Team
                            <div className={helpers.clx(styles.selectedTrapezium, currentScreen === "admin/team" ? styles.selectedTrapeziumSelected : "")}>
                                <div className={helpers.clx(styles.triangleHidden, currentScreen === "admin/team" ? styles.triangle : "")}/>
                            </div>
                        </li>      */}
                        <li className={helpers.clx(styles.menuItem, currentScreen === "admin/help-center" ? styles.menuItemSelected : "")} onClick={() => changeScreens("admin/help-center")}>
                            <div className={styles.navIcon}>
                                user-headset
                            </div>
                            Help Center
                            <div className={helpers.clx(styles.selectedTrapezium, currentScreen === "admin/help-center" ? styles.selectedTrapeziumSelected : "")}>
                                <div className={helpers.clx(styles.triangleHidden, currentScreen === "admin/help-center" ? styles.triangle : "")}/>
                            </div>
                        </li>                        
                    </>
                  :
                    <>
                        <li className={helpers.clx(styles.menuItem, currentScreen === "subscription" ? styles.menuItemSelected : "")} onClick={() => changeScreens("subscription")}>
                            <div className={styles.navIcon}>
                                user
                            </div>
                            My Biochar
                            <div className={helpers.clx(styles.selectedTrapezium, currentScreen === "subscription" ? styles.selectedTrapeziumSelected : "")}>
                                <div className={helpers.clx(styles.triangleHidden, currentScreen === "subscription" ? styles.triangle : "")}/>
                            </div>
                        </li>
                        <li className={helpers.clx(styles.menuItem, currentScreen === "orders" ? styles.menuItemSelected : "")} onClick={() => changeScreens("orders")}>
                            <div className={styles.navIcon}>
                                truck
                            </div>
                            Orders
                            <div className={helpers.clx(styles.selectedTrapezium, currentScreen === "orders" ? styles.selectedTrapeziumSelected : "")}>
                                <div className={helpers.clx(styles.triangleHidden, currentScreen === "orders" ? styles.triangle : "")}/>
                            </div>
                        </li>
                        <li className={helpers.clx(styles.menuItem, currentScreen === "fields" ? styles.menuItemSelected : "")} onClick={() => changeScreens("fields")}>
                            <div className={styles.navIcon}>
                                map-marked
                            </div>
                            Fields
                            <div className={helpers.clx(styles.selectedTrapezium, currentScreen === "invoices" ? styles.selectedTrapeziumSelected : "")}>
                                <div className={helpers.clx(styles.triangleHidden, currentScreen === "invoices" ? styles.triangle : "")}/>
                            </div>
                        </li>
                        <li className={helpers.clx(styles.menuItem, currentScreen === "invoices" ? styles.menuItemSelected : "")} onClick={() => changeScreens("invoices")}>
                            <div className={styles.navIcon}>
                                receipt
                            </div>
                            Invoices
                            <div className={helpers.clx(styles.selectedTrapezium, currentScreen === "invoices" ? styles.selectedTrapeziumSelected : "")}>
                                <div className={helpers.clx(styles.triangleHidden, currentScreen === "invoices" ? styles.triangle : "")}/>
                            </div>
                        </li>
                        {/* <li className={helpers.clx(styles.menuItem, currentScreen === "settings" ? styles.menuItemSelected : "")} onClick={() => changeScreens("settings")}>
                            <div className={styles.navIcon}>
                            wrench
                            </div>
                            Settings
                            <div className={helpers.clx(styles.selectedTrapezium, currentScreen === "settings" ? styles.selectedTrapeziumSelected : "")}>
                                <div className={helpers.clx(styles.triangleHidden, currentScreen === "settings" ? styles.triangle : "")}/>
                            </div>
                        </li> */}
                        <li className={helpers.clx(styles.menuItem, currentScreen === "support" ? styles.menuItemSelected : "")} onClick={() => changeScreens("support")}>
                            <div className={styles.navIcon}>
                                question
                            </div>
                            Support
                            <div className={helpers.clx(styles.selectedTrapezium, currentScreen === "support" ? styles.selectedTrapeziumSelected : "")}>
                                <div className={helpers.clx(styles.triangleHidden, currentScreen === "support" ? styles.triangle : "")}/>
                            </div>
                        </li>
                    </>
                }
                
            </ul>
            <div onClick={() => logout()} className={styles.logoutContainer}>
                Logout <span className={styles.logoutIcon}>sign-out-alt</span>
            </div>
            <div className={styles.bbbNavFooter}>
                BLACK BULL BIOCHAR
            </div>
        </div>
    )
}

Nav.defaultProps = {
    checked: false,
    isAdmin: false
  };

export default Nav;