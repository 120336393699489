import { Routes, Route, Navigate, useNavigate } from "react-router-dom"; //packages
import { useState, useCallback, useEffect } from 'react';

import { UserContext } from "./UserContext.js";

import HomeScreen from "./HomeScreen.js";
import SubscriptionScreen from "./SubscriptionScreen.js";
import UpdateSubscriptionScreen from "./components/subscription/UpdateSubscriptionScreen.js";
import UpdateDeliveryDetailsScreen from "./components/subscription/UpdateDeliveryDetailsScreen.js";
import UpdateBillingDetailsScreen from "./components/subscription/UpdateBillingDetailsScreen.js";
import ReviewSubscriptionScreen from "./components/subscription/ReviewSubscriptionScreen.js";
import ConfirmationScreen from "./components/subscription/ConfirmationScreen.js";



import LoginScreen from "./LoginScreen.js";
import SignUpScreen from "./SignUpScreen.js";
import ForgotPasswordScreen from "./ForgotPasswordScreen.js";
import AnalyticsScreen from "./AnalyticsScreen.js";
import SettingsScreen from "./SettingsScreen.js";
import SupportScreen from "./SupportScreen.js";
import OrdersScreen from "./OrdersScreen.js";
import InvoicesScreen from "./InvoicesScreen.js";
import FieldsScreen from "./FieldsScreen.js";


import AdminFarmersScreen from "./admin/AdminFarmersScreen.js";
import HelpCenterScreen from "./admin/HelpCenterScreen.js";
import TimelineScreen from "./admin/TimelineScreen.js";
import InventoryScreen from "./admin/InventoryScreen.js";
import CharcodesScreen from "./admin/CharcodesScreen.js";
import CharcodeScreen from "./CharcodeScreen.js";








import styles from './App.module.css'; //styles

//assets




const App = () => {
  const [token] = useState(null);
  
  /* 
    FOR CONTEXT THAT OFTEN CHANGES -> pass providerValue to context instead of state
    const providerValue = useMemo(() => ({value, setValue}), [value, setValue]) 
    
  */

  const [user, setUser] = useState({});

  const navigate = useNavigate(); //use callback



  const setUserDetails = (user, responseData, stayLoggedIn ) => {
      user.token = responseData.token;
      user.details = {};
      user.details.id = responseData.user._id;
      user.details.email = responseData.user.email;
      user.details.businessName = responseData.user.business_name;
      user.details.firstName = responseData.user.first_name;
      user.details.lastName = responseData.user.last_name;
      user.details.country = responseData.user.country;
      user.details.language = responseData.user.language;
      user.authed = true;
      if(stayLoggedIn){
        console.log("TOKEN SET")
        localStorage.setItem("token", user.token);
      }else{
        console.log("STAY LOGGED OUT")
        localStorage.removeItem("token");
      }
  }

  const setAdminDetails = (user, responseData, stayLoggedIn ) => {
    user.token = responseData.token;
    user.details = {};
    user.details.id = responseData.user._id;
    user.details.email = responseData.user.email;
    user.details.firstName = responseData.user.first_name;
    user.details.lastName = responseData.user.last_name;
    user.details.privileges = responseData.user.privileges;
    user.details.language = responseData.user.language;
    user.authed = true;
    if(stayLoggedIn){
      console.log("TOKEN SET")
      localStorage.setItem("token", user.token);
    }else{
      console.log("STAY LOGGED OUT")
      localStorage.removeItem("token");
    }
}

  // useEffect(() => {
  //   const initUser = async () => {

  //     /* USER OBJECT
  //     user = {
  //       authed: bool,
  //       token: string or null,
  //       backEndURL: str,
  //       details: {
  //         id: string,
  //         emaiL: string,
  //         firstName: string,
  //         lastName: string,
  //         language: string,
  //         country: string
  //       }
  //     }
  //     */

  //     let user = {};
  //     user.backEndURL = process.env.NODE_ENV === 'development' ? 'http://localhost:4000' : process.env.REACT_APP_API_BASE_URL;
  //     console.log("HERE")
  //     console.log(user.backEndURL)
  //     user.frontEndURL = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : process.env.REACT_APP_FRONT_END_URL;

  //     user.token = localStorage.getItem("token");

  //     if (user.token === null){
  //       console.log("NO TOKEN")
  //       user.authed = false;
  //     } else {
  //       // const user = await retrieveUserData(user.token);
  //       console.log(user);
  //       const request = {
  //         method: "post",
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + user.token,
  //         }
  //       };
    
  //       let response = await fetch(user.backEndURL + "/refresh-session", request);
  //       let jsonResponse = await response.json();
  //       if (jsonResponse.success) {
  //         setUserDetails(user, jsonResponse.data, true);
  //         console.log("SSSSILENTLY COMPLETES ITS MISSION, LIKE A NINJA");
  //       } else {
  //         console.log("WOOOOO")
  //       }
  //       //get user info here
  //       console.log(user)
  //     }
  //   // if(user.authed !== null){
  //     console.log(user)
  //     setUser(user);
  //     if (window.location.pathname.includes("/forgot-password") || window.location.pathname.includes("/sign-up")){
  //       return;
  //     }else if(user.authed){
  //       //navigate("/subscription"); UNCOMMMENT!!!!!!!!!
  //     }else if (window.location.pathname.includes("/charcode")){
  //       // navigate("/login");
  //     }else{
  //       navigate("/login");
  //     }
  //     // };
  //   }
  //   initUser();

  //   },[])



  useEffect(() => {
    let isMounted = true; // track if the component is still mounted
  
    const initUser = async () => {
      let user = {};
      user.backEndURL = window.location.href.includes("ngrok") ? 'https://bbb-staging-ae2bb81703e0.herokuapp.com' : process.env.NODE_ENV === 'development' ? 'http://localhost:4000' : process.env.REACT_APP_API_BASE_URL;
      user.frontEndURL = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : process.env.REACT_APP_FRONT_END_URL;
      user.token = localStorage.getItem("token");
  
      if (user.token === null) {
        console.log("NO TOKEN");
        user.authed = false;
      } else {
        console.log(user);
        const request = {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + user.token,
          }
        };
  
        try {
          console.log("TRYING TO REFRESH");
          let response = await fetch(user.backEndURL + "/refresh-session", request);
          
          let jsonResponse = await response.json();
  
          if (jsonResponse.success && isMounted) {
            setUserDetails(user, jsonResponse.data, true);
            console.log("SSSSILENTLY COMPLETES ITS MISSION, LIKE A NINJA");
          } else if (isMounted) {
            console.log("WOOOOO");
          }
        } catch (error) {
          if (isMounted) {
            console.error("Error during fetch:", error);
          }
        }
      }
  
      if (isMounted) {
        console.log(user);
        setUser(user);
        console.log(window.location.pathname)

        if (window.location.pathname.includes("/forgot-password") || window.location.pathname.includes("/sign-up")) {
          return;
        } else if (user.authed) {
          // navigate("/subscription"); UNCOMMENT!!!!!!!!!
        } else if (window.location.pathname.toLowerCase().includes("/charcode")) {
          console.log("YAY")
          // navigate("/login");
        } else {
          if (window.location.pathname.includes("/forgot-password"))
          navigate("/login");
        }
      }
    };
  
    initUser();
  
    // return () => {
    //   isMounted = false; // cleanup when component unmounts
    // };
  }, []); // Empty dependency array, runs once on mount

  // user.backEndURL, navigate
  const [screenTransitioning, setScreenTransitioning] = useState(false);

  // useEffect(() => {
  //   //defining the async function
   
  // }, [isAuthenticated])

  // // check if token valid before rehydrating, use lazy eval
  // if (localStorage.hasOwnProperty("user")) {
  //   console.log(localStorage.getItem("user").split("-")[2])
  //   console.log("Local storage found");
  //   this.state.authed = true;
  //   let user = {};
  //   user._id = localStorage.getItem("user").split("-")[0];
  //   user.name = localStorage.getItem("user").split("-")[1];
  //   user.token = localStorage.getItem("user").split("-")[2];
  //   this.state.user = user;
  // }


 




  const goToScreen = useCallback((screenName, params) => {
    setScreenTransitioning(true);
    setTimeout(() => {
      setTimeout(() => {
        setScreenTransitioning(false);
        //return navigate(screenName)
      }, 100)
      console.log(params);
        return navigate(screenName, {state: params});
    }, 200)  
  }, [navigate])


  return (
    <div className={styles.BgContainer}>
      <div className={styles.BgHexOverlay}/>
      <div className={styles.MainContainer}>
        <UserContext.Provider value={{user, setUser}}>
          <Routes> 
            <Route path="/" element={token !== null ? <Navigate to="/home" /> : <Navigate to="/login" />}/>
            
            {/* Auth Routes */}
            <Route path="/login" element={<LoginScreen setUserDetails={setUserDetails} setAdminDetails={setAdminDetails}/>}/>
            <Route path="/sign-up" element={<SignUpScreen/>}/>
            <Route path="/forgot-password" element={<ForgotPasswordScreen/>}/>

            {/* User Routes */}    
            <Route path="/home" element={<HomeScreen screenTransitioning={screenTransitioning} goToScreen={goToScreen}/>} />
            <Route path="/analytics" element={<AnalyticsScreen screenTransitioning={screenTransitioning} goToScreen={goToScreen}/>}/>
            <Route path="/subscription" element={<SubscriptionScreen screenTransitioning={screenTransitioning} goToScreen={goToScreen}/>} />
            <Route path="/update-subscription" element={<UpdateSubscriptionScreen screenTransitioning={screenTransitioning} goToScreen={goToScreen}/>} />
            <Route path="/update-delivery-details" element={<UpdateDeliveryDetailsScreen screenTransitioning={screenTransitioning} goToScreen={goToScreen}/>} />
            <Route path="/update-billing-details" element={<UpdateBillingDetailsScreen screenTransitioning={screenTransitioning} goToScreen={goToScreen}/>} />
            <Route path="/review-subscription" element={<ReviewSubscriptionScreen screenTransitioning={screenTransitioning} goToScreen={goToScreen}/>} />
            <Route path="/confirm-subscription" element={<ConfirmationScreen screenTransitioning={screenTransitioning} goToScreen={goToScreen}/>} />
            <Route path="/orders" element={<OrdersScreen screenTransitioning={screenTransitioning} goToScreen={goToScreen}/>} />
            <Route path="/invoices" element={<InvoicesScreen screenTransitioning={screenTransitioning} goToScreen={goToScreen}/>} />
            <Route path="/fields" element={<FieldsScreen screenTransitioning={screenTransitioning} goToScreen={goToScreen}/>}/>
            <Route path="/settings" element={<FieldsScreen screenTransitioning={screenTransitioning} goToScreen={goToScreen}/>}/>
            <Route path="/support" element={<SupportScreen screenTransitioning={screenTransitioning} goToScreen={goToScreen}/>}/>

            {/* Admin Routes */}    
            {/* <Route path="/admin/" element={<HomeScreen screenTransitioning={screenTransitioning} goToScreen={goToScreen}/>} /> */}
            <Route path="/admin/farmers" element={<AdminFarmersScreen screenTransitioning={screenTransitioning} goToScreen={goToScreen}/>}/>
            <Route path="/admin/help-center" element={<HelpCenterScreen screenTransitioning={screenTransitioning} goToScreen={goToScreen}/>}/>
            <Route path="/admin/timeline" element={<TimelineScreen screenTransitioning={screenTransitioning} goToScreen={goToScreen}/>}/>
            <Route path="/admin/charcodes" element={<CharcodesScreen screenTransitioning={screenTransitioning} goToScreen={goToScreen}/>}/>
            <Route path="/admin/inventory" element={<InventoryScreen screenTransitioning={screenTransitioning} goToScreen={goToScreen}/>}/>


            {/* Charcode Routes */}    
            <Route path="/charcode/:charcodeId" element={<CharcodeScreen goToScreen={goToScreen} backEndURL={user.backEndURL}/>} />

            
          </Routes>
        </UserContext.Provider>
      </div>  
    </div>
    
  );
}

export default App;

